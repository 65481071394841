/**
 * @file File containing icon definitions for the Icon ({@link ICON_MAP}) and GraphicIcon ({@link GRAPHIC_ICON_MAP}) components.
 */
import accountTreeFilledSvg from './icons/AccountTree.Filled.svg';
import accountTreeSvg from './icons/AccountTree.svg';
import addCircleSmallSvg from './icons/Add circle.Small.svg';
import addCircleSvg from './icons/Add circle.svg';
import addSvg from './icons/Add.svg';
import angledArrowDownLeftSvg from './icons/Angled arrow.Down left.svg';
import angledArrowDownRightSvg from './icons/Angled arrow.Down right.svg';
import angledArrowUpLeftSvg from './icons/Angled arrow.Up left.svg';
import angledArrowUpRightSvg from './icons/Angled arrow.Up right.svg';
import archiveSvg from './icons/Archive.svg';
import asteriskSvg from './icons/Asterisk.svg';
import audienceSvg from './icons/Audience.svg';
import backSvg from './icons/Back.svg';
import banditSmallSvg from './icons/Bandit.Small.svg';
import banditSvg from './icons/Bandit.svg';
import boltFilledSvg from './icons/Bolt.Filled.svg';
import boltUnfilledSvg from './icons/Bolt.Unfilled.svg';
import bugSmallSvg from './icons/Bug.Small.svg';
import bugSvg from './icons/Bug.svg';
import calendarClockSvg from './icons/Calendar clock.svg';
import calendarMonthSvg from './icons/Calendar month.svg';
import candlestickChartSvg from './icons/Candlestick chart.svg';
import cardActionIconsBulletListSvg from './icons/Card Action Icons.Bullet list.svg';
import cardActionIconsCheckListSvg from './icons/Card Action Icons.Check list.svg';
import cardActionIconsCodeBlockSvg from './icons/Card Action Icons.Code block.svg';
import cardActionIconsDividerSvg from './icons/Card Action Icons.Divider.svg';
import cardActionIconsExpMetricSvg from './icons/Card Action Icons.Exp metric.svg';
import cardActionIconsExploreSvg from './icons/Card Action Icons.Explore.svg';
import cardActionIconsHeading1Svg from './icons/Card Action Icons.Heading 1.svg';
import cardActionIconsHeading2Svg from './icons/Card Action Icons.Heading 2.svg';
import cardActionIconsHeading3Svg from './icons/Card Action Icons.Heading 3.svg';
import cardActionIconsImageSvg from './icons/Card Action Icons.Image.svg';
import cardActionIconsNumberedListSvg from './icons/Card Action Icons.Numbered list.svg';
import cardActionIconsPrimaryMetricSvg from './icons/Card Action Icons.Primary metric.svg';
import cardActionIconsTextSvg from './icons/Card Action Icons.Text.svg';
import cardActionIconsVariationSvg from './icons/Card Action Icons.Variation.svg';
import caretDownSvg from './icons/Caret.Down.svg';
import caretLeftSvg from './icons/Caret.Left.svg';
import caretRightSvg from './icons/Caret.Right.svg';
import caretUpSvg from './icons/Caret.Up.svg';
import certifiedSvg from './icons/Certified.svg';
import certifiedUncertifiedSvg from './icons/Certified.Uncertified.svg';
import chartIconsAnalysisSvg from './icons/Chart icons.Analysis.svg';
import chartIconsBarSvg from './icons/Chart icons.Bar.svg';
import chartIconsExploreSvg from './icons/Chart icons.Explore.svg';
import chartIconsLiftSvg from './icons/Chart icons.Lift.svg';
import chartIconsSvg from './icons/Chart icons.svg';
import chartIconsTimeSeriesSvg from './icons/Chart icons.Time series.svg';
import checkCircleFilledSmallSvg from './icons/Check circle.Filled small.svg';
import checkCircleFilledSvg from './icons/Check circle.Filled.svg';
import checkCircleUnfilledSmallSvg from './icons/Check circle.Unfilled small.svg';
import checkCircleUnfilledSvg from './icons/Check circle.Unfilled.svg';
import checkMarkSmallSvg from './icons/Check mark.Small.svg';
import checkMarkSvg from './icons/Check mark.svg';
import codeBlockSvg from './icons/Code block.svg';
import codeSvg from './icons/Code.svg';
import collapseCollapseAllSvg from './icons/Collapse.Collapse_all.svg';
import collapseExpandAllSvg from './icons/Collapse.Expand_all.svg';
import collectionSvg from './icons/Collection.svg';
import commentSvg from './icons/Comment.svg';
import confettiSmallSvg from './icons/Confetti.Small.svg';
import confettiSvg from './icons/Confetti.svg';
import copySvg from './icons/Copy.svg';
import cupedControlOffSvg from './icons/CUPED control.OFF.svg';
import cupedControlOnSvg from './icons/CUPED control.ON.svg';
import cupedSvg from './icons/CUPED.svg';
import dataTableSvg from './icons/Data table.svg';
import databaseFilledSvg from './icons/Database.Filled.svg';
import databaseUnfilledSvg from './icons/Database.Unfilled.svg';
import definitionsSvg from './icons/Definitions.svg';
import directionalArrowBackSvg from './icons/Directional arrow.Back.svg';
import directionalArrowDownwardSvg from './icons/Directional arrow.Downward.svg';
import directionalArrowForwardSvg from './icons/Directional arrow.Forward.svg';
import directionalArrowUpwardSvg from './icons/Directional arrow.Upward.svg';
import dotFilledBigSvg from './icons/Dot.Filled big.svg';
import dotFilledSmallSvg from './icons/Dot.Filled small.svg';
import dotUnfilledBigSvg from './icons/Dot.Unfilled big.svg';
import dotUnfilledSmallSvg from './icons/Dot.Unfilled small.svg';
import doubleArrowLeftSvg from './icons/Double arrow.Left.svg';
import doubleArrowRightSvg from './icons/Double arrow.Right.svg';
import downloadSvg from './icons/Download.svg';
import dragSvg from './icons/Drag.svg';
import editSvg from './icons/Edit.svg';
import equalSvg from './icons/Equal.svg';
import errorFilledSmallSvg from './icons/Error.Filled small.svg';
import errorFilledSvg from './icons/Error.Filled.svg';
import errorUnfilledSmallSvg from './icons/Error.Unfilled small.svg';
import errorUnfilledSvg from './icons/Error.Unfilled.svg';
import experimentSvg from './icons/Experiment.svg';
import exploreSvg from './icons/Explore.svg';
import featureFlagSvg from './icons/Feature flag.svg';
import featureGateSvg from './icons/Feature Gate.svg';
import fileSvg from './icons/File.svg';
import filterListSvg from './icons/Filter list.svg';
import filterFilledDefaultSvg from './icons/Filter.Filled default.svg';
import filterFilledOffSvg from './icons/Filter.Filled off.svg';
import filterUnfilledDefaultSvg from './icons/Filter.Unfilled default.svg';
import filterUnfilledOffSvg from './icons/Filter.Unfilled off.svg';
import firstPageArrowFirstPageSvg from './icons/First page arrow.first_page.svg';
import firstPageArrowLastPageSvg from './icons/First page arrow.last_page.svg';
import fixAndRepairSvg from './icons/Fix and repair.svg';
import folderSvg from './icons/Folder.svg';
import fullscreenExitFullscreenSvg from './icons/Fullscreen.Exit fullscreen.svg';
import fullscreenSvg from './icons/Fullscreen.svg';
import gearSvg from './icons/Gear.svg';
import giftSvg from './icons/Gift.svg';
import globeSvg from './icons/Globe.svg';
import happyFaceSmallSvg from './icons/Happy face.Small.svg';
import happyFaceSvg from './icons/Happy face.svg';
import hashtagSvg from './icons/Hashtag.svg';
import helpFilledSmallSvg from './icons/Help.Filled small.svg';
import helpFilledSvg from './icons/Help.Filled.svg';
import helpUnfilledSmallSvg from './icons/Help.Unfilled small.svg';
import helpUnfilledSvg from './icons/Help.Unfilled.svg';
import holdoutsFilledSvg from './icons/Holdouts.Filled.svg';
import holdoutsUnfilledSvg from './icons/Holdouts.Unfilled.svg';
import hourglassBottomFullSvg from './icons/Hourglass.Bottom full.svg';
import hourglassSvg from './icons/Hourglass.svg';
import hubSvg from './icons/Hub.svg';
import ideaSvg from './icons/Idea.svg';
import imageSvg from './icons/Image.svg';
import infoFilledSmallSvg from './icons/Info.Filled small.svg';
import infoFilledSvg from './icons/Info.Filled.svg';
import infoUnfilledSmallSvg from './icons/Info.Unfilled small.svg';
import infoUnfilledSvg from './icons/Info.Unfilled.svg';
import integrateSvg from './icons/Integrate.svg';
import jellybeansSvg from './icons/Jellybeans.svg';
import keySvg from './icons/Key.svg';
import killSwitchSvg from './icons/Kill Switch.svg';
import layersSvg from './icons/Layers.svg';
import legendDashedSvg from './icons/Legend.Dashed.svg';
import legendFilledSvg from './icons/Legend.Filled.svg';
import liftTriangleCircleDownSvg from './icons/Lift triangle.Circle down.svg';
import liftTriangleCircleUpSvg from './icons/Lift triangle.Circle up.svg';
import liftTriangleDownSvg from './icons/Lift triangle.Down.svg';
import liftTriangleUpSvg from './icons/Lift triangle.Up.svg';
import liftTriangleNeutralSvg from './icons/Lift.Triangle.neutral.svg';
import linkSvg from './icons/Link.svg';
import listSvg from './icons/List.svg';
import loadingSvg from './icons/Loading.svg';
import lockLockedSvg from './icons/Lock.Locked.svg';
import lockOpenSvg from './icons/Lock.Open.svg';
import loginSvg from './icons/Login.svg';
import logoAndroidSvg from './icons/Logo.Android.svg';
import logoAtlassianSvg from './icons/Logo.Atlassian.svg';
import logoBigQuerySvg from './icons/Logo.BigQuery.svg';
import logoDatabricksSvg from './icons/Logo.Databricks.svg';
import logoDovetailSvg from './icons/Logo.Dovetail.svg';
import logoEppoSvg from './icons/Logo.Eppo.svg';
import logoFigmaSvg from './icons/Logo.Figma.svg';
import logoGDocsSvg from './icons/Logo.g-docs.svg';
import logoGDriveSvg from './icons/Logo.g-drive.svg';
import logoSheetsSvg from './icons/Logo.g-sheets.svg';
import logoGSlidesSvg from './icons/Logo.g-slides.svg';
import logoGithubSvg from './icons/Logo.Github.svg';
import logoGitlabSvg from './icons/Logo.Gitlab.svg';
import logoGolangSvg from './icons/Logo.Golang.svg';
import logoIOsSvg from './icons/Logo.iOS.svg';
import logoJavaSvg from './icons/Logo.Java.svg';
import logoJavaScriptSvg from './icons/Logo.JavaScript.svg';
import logoLinearSvg from './icons/Logo.Linear.svg';
import logoLoomSvg from './icons/Logo.Loom.svg';
import logoNodejsSvg from './icons/Logo.Nodejs.svg';
import logoNotionSvg from './icons/Logo.Notion.svg';
import logoPhpSvg from './icons/Logo.PHP.svg';
import logoPythonSvg from './icons/Logo.Python.svg';
import logoRedshiftSvg from './icons/Logo.Redshift.svg';
import logoRubySvg from './icons/Logo.Ruby.svg';
import logoSlackSvg from './icons/Logo.Slack.svg';
import logoSnowflakeSvg from './icons/Logo.Snowflake.svg';
import logoutSvg from './icons/Logout.svg';
import magicWandSvg from './icons/Magic wand.svg';
import mailSvg from './icons/Mail.svg';
import mapSvg from './icons/Map.svg';
import metricDefaultMetricSvg from './icons/Metric.Default metric.svg';
import metricGuardrailSvg from './icons/Metric.Guardrail.svg';
import metricSvg from './icons/Metric.svg';
import microscopeSvg from './icons/Microscope.svg';
import moreHorizontalSvg from './icons/More.Horizontal.svg';
import moreVerticalSvg from './icons/More.Vertical.svg';
import natureIconAcornSvg from './icons/Nature Icon.Acorn.svg';
import natureIconBirdHouseSvg from './icons/Nature Icon.Bird House.svg';
import natureIconButterflySvg from './icons/Nature Icon.Butterfly.svg';
import natureIconGemSvg from './icons/Nature Icon.Gem.svg';
import natureIconGrassSvg from './icons/Nature Icon.Grass.svg';
import natureIconHailSvg from './icons/Nature Icon.Hail.svg';
import natureIconHummingBirdSvg from './icons/Nature Icon.Humming Bird.svg';
import natureIconLandscape2Svg from './icons/Nature Icon.Landscape 2.svg';
import natureIconLeafSvg from './icons/Nature Icon.Leaf.svg';
import natureIconLightningSvg from './icons/Nature Icon.Lightning.svg';
import natureIconMountainSvg from './icons/Nature Icon.Mountain.svg';
import natureIconMushroomSvg from './icons/Nature Icon.Mushroom.svg';
import natureIconPalmSvg from './icons/Nature Icon.Palm.svg';
import natureIconPotSvg from './icons/Nature Icon.Pot.svg';
import natureIconReedSvg from './icons/Nature Icon.Reed.svg';
import natureIconRoseSvg from './icons/Nature Icon.Rose.svg';
import natureIconSunSvg from './icons/Nature Icon.Sun.svg';
import natureIconTreeSvg from './icons/Nature Icon.Tree.svg';
import natureIconTulip1Svg from './icons/Nature Icon.Tulip 1.svg';
import natureIconTulip2Svg from './icons/Nature Icon.Tulip 2.svg';
import neutralFaceSmallSvg from './icons/Neutral face.Small.svg';
import neutralFaceSvg from './icons/Neutral face.svg';
import newPageSvg from './icons/New page.svg';
import notificationsFilledSvg from './icons/Notifications.Filled.svg';
import notificationsUnfilledSvg from './icons/Notifications.Unfilled.svg';
import openOpenDownSvg from './icons/Open.Open down.svg';
import openOpenInNewSvg from './icons/Open.Open in new.svg';
import pie2575Svg from './icons/Pie.25-75.svg';
import pie5050Svg from './icons/Pie.50-50.svg';
import pie7525Svg from './icons/Pie.75-25.svg';
import pinFilledSvg from './icons/Pin.Filled.svg';
import pinUnfilledSvg from './icons/Pin.Unfilled.svg';
import playFilledSvg from './icons/Play.Filled.svg';
import playUnfilledSvg from './icons/Play.Unfilled.svg';
import protocolSvg from './icons/Protocol.svg';
import refreshSvg from './icons/Refresh.svg';
import removeCircleSmallSvg from './icons/Remove circle.Small.svg';
import removeCircleSvg from './icons/Remove circle.svg';
import restartSvg from './icons/Restart.svg';
import richTextIconsBulletedListSvg from './icons/Rich Text Icons.bulleted-list.svg';
import richTextIconsCodeBlockSvg from './icons/Rich Text Icons.Code block.svg';
import richTextIconsHorizontalRuleSvg from './icons/Rich Text Icons.horizontal-rule.svg';
import richTextIconsImageSvg from './icons/Rich Text Icons.Image.svg';
import richTextIconsInlineCodeSvg from './icons/Rich Text Icons.Inline code.svg';
import richTextIconsItalicSvg from './icons/Rich Text Icons.italic.svg';
import richTextIconsLinkSvg from './icons/Rich Text Icons.Link.svg';
import richTextIconsListNumberedSvg from './icons/Rich Text Icons.list-numbered.svg';
import richTextIconsQuotesSvg from './icons/Rich Text Icons.quotes.svg';
import richTextIconsRedoSvg from './icons/Rich Text Icons.redo.svg';
import richTextIconsTextAlignCenterSvg from './icons/Rich Text Icons.text-align-center.svg';
import richTextIconsTextAlignJustifySvg from './icons/Rich Text Icons.text-align-justify.svg';
import richTextIconsTextAlignLeftSvg from './icons/Rich Text Icons.text-align-left.svg';
import richTextIconsTextAlignRightSvg from './icons/Rich Text Icons.text-align-right.svg';
import richTextIconsTextBoldSvg from './icons/Rich Text Icons.text-bold.svg';
import richTextIconsTextClearFormatSvg from './icons/Rich Text Icons.text-clear-format.svg';
import richTextIconsTextColorSvg from './icons/Rich Text Icons.text-color.svg';
import richTextIconsTextStrikethroughSvg from './icons/Rich Text Icons.text-strikethrough.svg';
import richTextIconsTextUnderlineSvg from './icons/Rich Text Icons.text-underline.svg';
import richTextIconsUndoSvg from './icons/Rich Text Icons.undo.svg';
import rocketSmallSvg from './icons/Rocket.Small.svg';
import rocketSvg from './icons/Rocket.svg';
import sadFaceSmallSvg from './icons/Sad face.Small.svg';
import sadFaceSvg from './icons/Sad face.svg';
import searchSvg from './icons/Search.svg';
import sendSvg from './icons/Send.svg';
import shareSvg from './icons/Share.svg';
import smileFaceSmallSvg from './icons/Smile face.Small.svg';
import smileFaceSvg from './icons/Smile face.svg';
import sortSortDownSvg from './icons/Sort.Sort down.svg';
import sortSortUpSvg from './icons/Sort.Sort up.svg';
import sortSvg from './icons/Sort.svg';
import speakerSvg from './icons/Speaker.svg';
import sqlSvg from './icons/SQL.svg';
import starFilledSmallSvg from './icons/Star.Filled small.svg';
import starFilledSvg from './icons/Star.Filled.svg';
import starUnfilledSmallSvg from './icons/Star.Unfilled small.svg';
import starUnfilledSvg from './icons/Star.Unfilled.svg';
import teamSvg from './icons/Team.svg';
import terminalSvg from './icons/Terminal.svg';
import thumbThumbDownSvg from './icons/Thumb.thumb_down.svg';
import thumbThumbUpSvg from './icons/Thumb.thumb_up.svg';
import timerSvg from './icons/Timer.svg';
import trashSvg from './icons/Trash.svg';
import trendTrendingDownSvg from './icons/Trend.trending_down.svg';
import trendTrendingFlatSvg from './icons/Trend.trending_flat.svg';
import trendTrendingUpSvg from './icons/Trend.trending_up.svg';
import troubleshootSvg from './icons/Troubleshoot.svg';
import tuneHorizontalSvg from './icons/Tune.Horizontal.svg';
import tuneVerticalSvg from './icons/Tune.Vertical.svg';
import unhappyFaceSmallSvg from './icons/Unhappy face.Small.svg';
import unhappyFaceSvg from './icons/Unhappy face.svg';
import uploadFilledSvg from './icons/Upload.Filled.svg';
import uploadUnfilledSvg from './icons/Upload.Unfilled.svg';
import visibilityOffSvg from './icons/Visibility.Off.svg';
import visibilitySvg from './icons/Visibility.svg';
import warningFilledSmallSvg from './icons/Warning.Filled small.svg';
import warningFilledSvg from './icons/Warning.Filled.svg';
import warningUnfilledSmallSvg from './icons/Warning.Unfilled small.svg';
import warningUnfilledSvg from './icons/Warning.Unfilled.svg';
import winnerSvg from './icons/Winner.svg';
import xSmallSvg from './icons/X.Small.svg';
import xSvg from './icons/X.svg';
import zoomZoomInSvg from './icons/Zoom.zoom_in.svg';
import zoomZoomOutSvg from './icons/Zoom.zoom_out.svg';

/**
 * A map of single color SVG icon definitions. Keys are icon component names from Figma, values are .svg file imports.
 *
 * Separate icon component variants should be defined as separate icons. Figma icon component names are changed a bit, for example:
 * "MD Icon/Filter[Property=Filled default]" => "Filter.Filled default",
 * "Icon/Holdouts[Property 1=Unfilled]" => "Holdouts.Unfilled".
 *
 * To define **graphic** icons (aka which should preserve their colors from Figma), see {@link GRAPHIC_ICON_MAP}.
 *
 * Figma definition: {@link https://www.figma.com/design/q5V3oq0GCqLdnK6SjG2Ghl/%F0%9F%A7%B1-%5BOFFICIAL%5D-Foundations?node-id=2895-12377&m=dev | Foundations / Icons}.
 */
export const ICON_MAP = {
  'Account tree': accountTreeSvg,
  'Account tree.Filled': accountTreeFilledSvg,
  Add: addSvg,
  'Add circle': addCircleSvg,
  'Add circle.Small': addCircleSmallSvg,
  Archive: archiveSvg,
  Asterisk: asteriskSvg,
  Back: backSvg,
  Bug: bugSvg,
  'Bug.Small': bugSmallSvg,
  'Calendar clock': calendarClockSvg,
  'Calendar month': calendarMonthSvg,
  'Candlestick chart': candlestickChartSvg,
  'Caret.Down': caretDownSvg,
  'Caret.Left': caretLeftSvg,
  'Caret.Right': caretRightSvg,
  'Caret.Up': caretUpSvg,
  Certified: certifiedSvg,
  'Certified.Uncertified': certifiedUncertifiedSvg,
  'Check circle.Filled': checkCircleFilledSvg,
  'Check circle.Filled small': checkCircleFilledSmallSvg,
  'Check circle.Unfilled': checkCircleUnfilledSvg,
  'Check circle.Unfilled small': checkCircleUnfilledSmallSvg,
  'Check mark': checkMarkSvg,
  'Check mark.Small': checkMarkSmallSvg,
  Code: codeSvg,
  'Code block': codeBlockSvg,
  'Collapse.Collapse_all': collapseCollapseAllSvg,
  'Collapse.Expand_all': collapseExpandAllSvg,
  Comment: commentSvg,
  Copy: copySvg,
  'Data table': dataTableSvg,
  'Database.Filled': databaseFilledSvg,
  'Database.Unfilled': databaseUnfilledSvg,
  Definitions: definitionsSvg,
  'Directional arrow.Back': directionalArrowBackSvg,
  'Directional arrow.Downward': directionalArrowDownwardSvg,
  'Directional arrow.Forward': directionalArrowForwardSvg,
  'Directional arrow.Upward': directionalArrowUpwardSvg,
  'Double arrow.Left': doubleArrowLeftSvg,
  'Double arrow.Right': doubleArrowRightSvg,
  Download: downloadSvg,
  Drag: dragSvg,
  Edit: editSvg,
  'Error.Filled': errorFilledSvg,
  'Error.Filled small': errorFilledSmallSvg,
  'Error.Unfilled': errorUnfilledSvg,
  'Error.Unfilled small': errorUnfilledSmallSvg,
  Experiment: experimentSvg,
  Explore: exploreSvg,
  'Feature flag': featureFlagSvg,
  'Feature Gate': featureGateSvg,
  File: fileSvg,
  'Filter list': filterListSvg,
  'Filter.Filled default': filterFilledDefaultSvg,
  'Filter.Filled off': filterFilledOffSvg,
  'Filter.Unfilled default': filterUnfilledDefaultSvg,
  'Filter.Unfilled off': filterUnfilledOffSvg,
  'First page arrow.first_page': firstPageArrowFirstPageSvg,
  'First page arrow.last_page': firstPageArrowLastPageSvg,
  'Fix and repair': fixAndRepairSvg,
  Folder: folderSvg,
  Gear: gearSvg,
  Gift: giftSvg,
  Globe: globeSvg,
  'Happy face': happyFaceSvg,
  'Happy face.Small': happyFaceSmallSvg,
  'Help.Filled': helpFilledSvg,
  'Help.Filled small': helpFilledSmallSvg,
  'Help.Unfilled': helpUnfilledSvg,
  'Help.Unfilled small': helpUnfilledSmallSvg,
  Hourglass: hourglassSvg,
  'Hourglass.Bottom full': hourglassBottomFullSvg,
  Hub: hubSvg,
  Idea: ideaSvg,
  Image: imageSvg,
  'Info.Filled': infoFilledSvg,
  'Info.Filled small': infoFilledSmallSvg,
  'Info.Unfilled': infoUnfilledSvg,
  'Info.Unfilled small': infoUnfilledSmallSvg,
  Key: keySvg,
  'Kill Switch': killSwitchSvg,
  Layers: layersSvg,
  'Lift triangle.Circle down': liftTriangleCircleDownSvg,
  'Lift triangle.Circle up': liftTriangleCircleUpSvg,
  'Lift triangle.Down': liftTriangleDownSvg,
  'Lift triangle.Up': liftTriangleUpSvg,
  'Lift triangle.Neutral': liftTriangleNeutralSvg,
  Link: linkSvg,
  List: listSvg,
  'Lock.Locked': lockLockedSvg,
  'Lock.Open': lockOpenSvg,
  Login: loginSvg,
  Logout: logoutSvg,
  Mail: mailSvg,
  Map: mapSvg,
  Metric: metricSvg,
  'Metric.Default metric': metricDefaultMetricSvg,
  'Metric.Guardrail': metricGuardrailSvg,
  Microscope: microscopeSvg,
  'More.Horizontal': moreHorizontalSvg,
  'More.Vertical': moreVerticalSvg,
  'Neutral face': neutralFaceSvg,
  'Neutral face.Small': neutralFaceSmallSvg,
  'New page': newPageSvg,
  'Notifications.Filled': notificationsFilledSvg,
  'Notifications.Unfilled': notificationsUnfilledSvg,
  'Open.Open down': openOpenDownSvg,
  'Open.Open in new': openOpenInNewSvg,
  'Pin.Filled': pinFilledSvg,
  'Pin.Unfilled': pinUnfilledSvg,
  'Play.Filled': playFilledSvg,
  'Play.Unfilled': playUnfilledSvg,
  Refresh: refreshSvg,
  'Remove circle': removeCircleSvg,
  'Remove circle.Small': removeCircleSmallSvg,
  Restart: restartSvg,
  Rocket: rocketSvg,
  'Rocket.Small': rocketSmallSvg,
  'Sad face': sadFaceSvg,
  'Sad face.Small': sadFaceSmallSvg,
  Search: searchSvg,
  Send: sendSvg,
  Share: shareSvg,
  'Smile face': smileFaceSvg,
  'Smile face.Small': smileFaceSmallSvg,
  Speaker: speakerSvg,
  'Star.Filled': starFilledSvg,
  'Star.Filled small': starFilledSmallSvg,
  'Star.Unfilled': starUnfilledSvg,
  'Star.Unfilled small': starUnfilledSmallSvg,
  Team: teamSvg,
  Terminal: terminalSvg,
  'Thumb.thumb_down': thumbThumbDownSvg,
  'Thumb.thumb_up': thumbThumbUpSvg,
  Timer: timerSvg,
  Trash: trashSvg,
  'Trend.trending_down': trendTrendingDownSvg,
  'Trend.trending_flat': trendTrendingFlatSvg,
  'Trend.trending_up': trendTrendingUpSvg,
  Troubleshoot: troubleshootSvg,
  'Tune.Horizontal': tuneHorizontalSvg,
  'Tune.Vertical': tuneVerticalSvg,
  'Unhappy face': unhappyFaceSvg,
  'Unhappy face.Small': unhappyFaceSmallSvg,
  'Upload.Filled': uploadFilledSvg,
  'Upload.Unfilled': uploadUnfilledSvg,
  Visibility: visibilitySvg,
  'Visibility.Off': visibilityOffSvg,
  'Warning.Filled': warningFilledSvg,
  'Warning.Filled small': warningFilledSmallSvg,
  'Warning.Unfilled': warningUnfilledSvg,
  'Warning.Unfilled small': warningUnfilledSmallSvg,
  Winner: winnerSvg,
  X: xSvg,
  'X.Small': xSmallSvg,
  'Zoom.zoom_in': zoomZoomInSvg,
  'Zoom.zoom_out': zoomZoomOutSvg,
  Bandit: banditSvg,
  'Bandit.Small': banditSmallSvg,
  'Chart icons': chartIconsSvg,
  'Chart icons.Analysis': chartIconsAnalysisSvg,
  'Chart icons.Explore': chartIconsExploreSvg,
  'Chart icons.Bar': chartIconsBarSvg,
  'Chart icons.Lift': chartIconsLiftSvg,
  'Chart icons.Time series': chartIconsTimeSeriesSvg,
  Collection: collectionSvg,
  Confetti: confettiSvg,
  'Confetti.Small': confettiSmallSvg,
  CUPED: cupedSvg,
  'CUPED control.OFF': cupedControlOffSvg,
  'CUPED control.ON': cupedControlOnSvg,
  'Rich Text Icons.Code block': richTextIconsCodeBlockSvg,
  'Rich Text Icons.Image': richTextIconsImageSvg,
  'Rich Text Icons.Inline code': richTextIconsInlineCodeSvg,
  'Rich Text Icons.Link': richTextIconsLinkSvg,
  'Rich Text Icons.bulleted-list': richTextIconsBulletedListSvg,
  'Rich Text Icons.horizontal-rule': richTextIconsHorizontalRuleSvg,
  'Rich Text Icons.italic': richTextIconsItalicSvg,
  'Rich Text Icons.list-numbered': richTextIconsListNumberedSvg,
  'Rich Text Icons.quotes': richTextIconsQuotesSvg,
  'Rich Text Icons.redo': richTextIconsRedoSvg,
  'Rich Text Icons.text-align-center': richTextIconsTextAlignCenterSvg,
  'Rich Text Icons.text-align-justify': richTextIconsTextAlignJustifySvg,
  'Rich Text Icons.text-align-left': richTextIconsTextAlignLeftSvg,
  'Rich Text Icons.text-align-right': richTextIconsTextAlignRightSvg,
  'Rich Text Icons.text-bold': richTextIconsTextBoldSvg,
  'Rich Text Icons.text-clear-format': richTextIconsTextClearFormatSvg,
  'Rich Text Icons.text-color': richTextIconsTextColorSvg,
  'Rich Text Icons.text-strikethrough': richTextIconsTextStrikethroughSvg,
  'Rich Text Icons.text-underline': richTextIconsTextUnderlineSvg,
  'Rich Text Icons.undo': richTextIconsUndoSvg,
  'Holdouts.Filled': holdoutsFilledSvg,
  'Holdouts.Unfilled': holdoutsUnfilledSvg,
  Fullscreen: fullscreenSvg,
  'Fullscreen.Exit fullscreen': fullscreenExitFullscreenSvg,
  Sort: sortSvg,
  'Sort.Sort down': sortSortDownSvg,
  'Sort.Sort up': sortSortUpSvg,
  SQL: sqlSvg,
  'Magic wand': magicWandSvg,
  'Angled arrow.Down left': angledArrowDownLeftSvg,
  'Angled arrow.Down right': angledArrowDownRightSvg,
  'Angled arrow.Up left': angledArrowUpLeftSvg,
  'Angled arrow.Up right': angledArrowUpRightSvg,
  'Bolt.Filled': boltFilledSvg,
  'Bolt.Unfilled': boltUnfilledSvg,
  Equal: equalSvg,
  Hashtag: hashtagSvg,
  Integrate: integrateSvg,
  Audience: audienceSvg,
  Jellybeans: jellybeansSvg,
  'Nature Icon.Acorn': natureIconAcornSvg,
  'Nature Icon.Bird House': natureIconBirdHouseSvg,
  'Nature Icon.Butterfly': natureIconButterflySvg,
  'Nature Icon.Gem': natureIconGemSvg,
  'Nature Icon.Grass': natureIconGrassSvg,
  'Nature Icon.Hail': natureIconHailSvg,
  'Nature Icon.Humming Bird': natureIconHummingBirdSvg,
  'Nature Icon.Landscape 2': natureIconLandscape2Svg,
  'Nature Icon.Leaf': natureIconLeafSvg,
  'Nature Icon.Lightning': natureIconLightningSvg,
  'Nature Icon.Mountain': natureIconMountainSvg,
  'Nature Icon.Mushroom': natureIconMushroomSvg,
  'Nature Icon.Palm': natureIconPalmSvg,
  'Nature Icon.Pot': natureIconPotSvg,
  'Nature Icon.Reed': natureIconReedSvg,
  'Nature Icon.Rose': natureIconRoseSvg,
  'Nature Icon.Sun': natureIconSunSvg,
  'Nature Icon.Tree': natureIconTreeSvg,
  'Nature Icon.Tulip 1': natureIconTulip1Svg,
  'Nature Icon.Tulip 2': natureIconTulip2Svg,
  'Dot.Filled big': dotFilledBigSvg,
  'Dot.Filled small': dotFilledSmallSvg,
  'Dot.Unfilled big': dotUnfilledBigSvg,
  'Dot.Unfilled small': dotUnfilledSmallSvg,
  'Card Action Icons.Bullet list': cardActionIconsBulletListSvg,
  'Card Action Icons.Check list': cardActionIconsCheckListSvg,
  'Card Action Icons.Code block': cardActionIconsCodeBlockSvg,
  'Card Action Icons.Divider': cardActionIconsDividerSvg,
  'Card Action Icons.Exp metric': cardActionIconsExpMetricSvg,
  'Card Action Icons.Explore': cardActionIconsExploreSvg,
  'Card Action Icons.Heading 1': cardActionIconsHeading1Svg,
  'Card Action Icons.Heading 2': cardActionIconsHeading2Svg,
  'Card Action Icons.Heading 3': cardActionIconsHeading3Svg,
  'Card Action Icons.Image': cardActionIconsImageSvg,
  'Card Action Icons.Numbered list': cardActionIconsNumberedListSvg,
  'Card Action Icons.Primary metric': cardActionIconsPrimaryMetricSvg,
  'Card Action Icons.Text': cardActionIconsTextSvg,
  'Card Action Icons.Variation': cardActionIconsVariationSvg,
  Protocol: protocolSvg
};
export type IconName = keyof typeof ICON_MAP;
export const isValidIconName = (input: unknown): input is IconName => {
  return Boolean(ICON_MAP[(input as IconName)]);
};

/**
 * A map of graphic colored SVG icon definitions. Keys are icon component names from Figma, values are .svg file imports.
 *
 * Separate icon component variants should be defined as separate icons. Figma icon component names are changed a bit, for example:
 * "Icon/Logo[Property 1=Slack]" => "Logo.Slack".
 *
 * To define plain single-color icons, see {@link ICON_MAP}.
 *
 * Figma definition: {@link https://www.figma.com/design/q5V3oq0GCqLdnK6SjG2Ghl/%F0%9F%A7%B1-%5BOFFICIAL%5D-Foundations?node-id=2895-12377&m=dev | Foundations / Icons}.
 *
 * Some of the icons in this map was scraped from existing colored icons within the codebase.
 */
export const GRAPHIC_ICON_MAP = {
  'Logo.Atlassian': logoAtlassianSvg,
  'Logo.Dovetail': logoDovetailSvg,
  'Logo.Eppo': logoEppoSvg,
  'Logo.Figma': logoFigmaSvg,
  'Logo.Github': logoGithubSvg,
  'Logo.Gitlab': logoGitlabSvg,
  'Logo.Linear': logoLinearSvg,
  'Logo.Loom': logoLoomSvg,
  'Logo.Notion': logoNotionSvg,
  'Logo.g-sheets': logoSheetsSvg,
  'Logo.Slack': logoSlackSvg,
  'Logo.g-docs': logoGDocsSvg,
  'Logo.g-drive': logoGDriveSvg,
  'Logo.g-slides': logoGSlidesSvg,
  'Logo.Android': logoAndroidSvg,
  'Logo.BigQuery': logoBigQuerySvg,
  'Logo.Databricks': logoDatabricksSvg,
  'Logo.Golang': logoGolangSvg,
  'Logo.Java': logoJavaSvg,
  'Logo.JavaScript': logoJavaScriptSvg,
  'Logo.Nodejs': logoNodejsSvg,
  'Logo.PHP': logoPhpSvg,
  'Logo.Python': logoPythonSvg,
  'Logo.Redshift': logoRedshiftSvg,
  'Logo.Ruby': logoRubySvg,
  'Logo.Snowflake': logoSnowflakeSvg,
  'Logo.iOS': logoIOsSvg,
  'Legend.Dashed': legendDashedSvg,
  'Legend.Filled': legendFilledSvg,
  'Pie.25-75': pie2575Svg,
  'Pie.50-50': pie5050Svg,
  'Pie.75-25': pie7525Svg,
  Loading: loadingSvg
};
export type GraphicIconName = keyof typeof GRAPHIC_ICON_MAP;
export const isValidGraphicIconName = (input: unknown): input is GraphicIconName => {
  return Boolean(GRAPHIC_ICON_MAP[(input as GraphicIconName)]);
};