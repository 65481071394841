import { useEffect, useMemo, useState } from 'react';
import { EFeatureFlag } from 'constants/featureFlags';
import axios from 'axios';
import { useRouter } from 'next/router';
import { graphql, PreloadedQuery, useMutation, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useBoolFlagAssignment, useStringFlagOn } from 'components/feature-flag/hooks/useFlagAssignment';
import { isCompleted } from 'components/getting-started/helpers';
import { Suspense } from 'components/Suspense';
import useRbacPermissions from 'hooks/useRbacPermissions';
import { useUser } from 'hooks/useUser';
import Sidebar, { ISideMenuOption } from './nav/Sidebar';
import { MenuOptionsSidebar_Query, MenuOptionsSidebar_Query$data } from './__generated__/MenuOptionsSidebar_Query.graphql';
import { MenuOptionsSidebarRecordUpdatesClicked_Mutation } from './__generated__/MenuOptionsSidebarRecordUpdatesClicked_Mutation.graphql';
const QUERY = graphql`
  query MenuOptionsSidebar_Query($userId: Int!) {
    userCompany {
      companyId
      name
      isEppoOwned
    }
    companyEnabledFeatures {
      featureFlaggingStatus
    }
    user(userId: $userId) {
      id
      lastClickedUpdates
      createdAt
    }
    experimentationGettingStartedState {
      hasDataWarehouseConnection
      hasAtLeastOneEntity
      hasAtLeastOneAssignmentSQL
      hasAtLeastOneFactSQL
      hasAtLeastOneMetric
      hasAtLeastOneExperiment
      hasAtLeastOneTeam
    }
    randomizationGettingStartedState {
      hasAtLeastOneSDKKey
      hasAtLeastOneFeatureFlag
      hasAtLeastOneFeatureFlagWithAllocation
      hasAtLeastOneFeatureFlagWithStatusEnabled
      hasAtLeastOneUsedSDKKey
      hasAtLeastOneAssignmentSQL
      hasAtLeastOneTeam
    }
  }
`;
const RECORD_UPDATES_CLICKED_MUTATION = graphql`
  mutation MenuOptionsSidebarRecordUpdatesClicked_Mutation($input: UserEventInput!) {
    recordUpdatesClicked(input: $input) {
      success
    }
  }
`;
const getMenuOptions = ({
  companyEnabledFeatures = {},
  isAdminEnabled,
  isTeamsEnabled,
  canUpdateDefinitions,
  isGettingStartedEnabled,
  isInsightsEnabled,
  isPipelineVisibilityEnabled
}: {
  companyEnabledFeatures?: Partial<MenuOptionsSidebar_Query$data['companyEnabledFeatures']>;
  isAdminEnabled: boolean;
  isTeamsEnabled: boolean;
  canUpdateDefinitions: boolean;
  isGettingStartedEnabled: boolean;
  isInsightsEnabled: boolean;
  isPipelineVisibilityEnabled: boolean;
}): ISideMenuOption[] => {
  // this is very meta, but we want to have the feature "feature flag trials" behind a feature flag 😂
  // eslint-disable-next-line
  const isFeatureFlagsTrialsFeatureEnabled = useBoolFlagAssignment(EFeatureFlag.FeatureFlagTrials);
  const {
    featureFlaggingStatus
  } = companyEnabledFeatures;
  const isFeatureFlagsAccessible = featureFlaggingStatus && ['ENABLED', 'TRIAL_ACTIVE', 'TRIAL_ENDED'].includes(featureFlaggingStatus);
  // Is the company eligible to start a feature flags trial?
  const canStartFeatureFlagsTrial = !isFeatureFlagsAccessible && isFeatureFlagsTrialsFeatureEnabled && featureFlaggingStatus === 'TRIAL_ELIGIBLE';
  const isConfigurationOptionVisible = isFeatureFlagsAccessible || canStartFeatureFlagsTrial;
  const featureFlaggingUrl = isFeatureFlagsAccessible ? '/feature-flags' : '/feature-flags/trials';
  return [{
    name: 'Getting Started',
    url: '/getting-started',
    isHidden: !isGettingStartedEnabled
  }, {
    name: 'Insights',
    url: '/insights',
    isHidden: !isInsightsEnabled
  }, {
    name: 'Analysis',
    url: '/experiments'
  }, {
    name: 'Configuration',
    url: featureFlaggingUrl,
    isHidden: !isConfigurationOptionVisible
  }, {
    name: 'Metrics',
    url: '/metrics'
  }, {
    name: 'Definitions',
    url: '/definitions',
    isHidden: !canUpdateDefinitions
  }, {
    name: 'Teams',
    url: '/teams',
    isHidden: !isTeamsEnabled
  }, {
    name: 'Warehouse',
    url: '/warehouse',
    isHidden: !isPipelineVisibilityEnabled
  }, {
    name: 'Admin',
    url: '/admin',
    isHidden: !isAdminEnabled
  }];
};
export default function MenuOptionsSidebar(): JSX.Element {
  const {
    userId
  } = useUser();
  const router = useRouter();
  const [updatesClicked, setUpdatesClicked] = useState(false);
  const [recordUpdateClicked] = useMutation<MenuOptionsSidebarRecordUpdatesClicked_Mutation>(RECORD_UPDATES_CLICKED_MUTATION);
  const bottomOptions = useMemo(() => {
    // Note: we will be temporarily be using a Bandit to optimize how we highlight new updates
    const recordBanditMetric = async () => {
      const subjectKey = router.query.subjectKey ?? userId;
      await axios.post('https://us-central1-eppo-qa.cloudfunctions.net/java-http-function/record', {
        subjectKey,
        metricKey: 'click',
        metricValue: 'updates'
      });
    };
    const handleUpdatesSelect = () => {
      // remove any active highlighting
      setUpdatesClicked(true);
      // update most recent timestamp in the Eppo database
      recordUpdateClicked({
        variables: {
          input: {
            id: userId
          }
        }
      });
      // record the bandit metric event
      recordBanditMetric().catch(e => console.warn('Unable to record Updates clicked', e));
    };
    return [{
      url: 'https://updates.eppo.cloud/en',
      name: 'Updates',
      handleSelect: handleUpdatesSelect,
      forceNewTab: true
    }, {
      url: 'https://docs.geteppo.com/',
      name: 'Docs'
    }];
  }, [recordUpdateClicked, setUpdatesClicked, userId, router.query.subjectKey]);
  const [queryReference, loadQuery] = useQueryLoader<MenuOptionsSidebar_Query>(QUERY);
  useEffect(() => {
    if (userId) {
      loadQuery({
        userId
      });
    }
  }, [loadQuery, userId]);
  const menuOptions = getMenuOptions({
    isAdminEnabled: false,
    isTeamsEnabled: false,
    canUpdateDefinitions: false,
    isGettingStartedEnabled: false,
    isInsightsEnabled: false,
    isPipelineVisibilityEnabled: false
  });

  // fallback without loading FF enabled menu items
  const fallback = <Sidebar options={menuOptions} bottomOptions={bottomOptions} />;
  return <Suspense fallback={fallback}>
      {queryReference && <MenuOptionsSidebarLayout featureFlagConnectionsRef={queryReference} bottomOptions={bottomOptions} updatesClicked={updatesClicked} />}
    </Suspense>;
}
interface IMenuOptionsSidebarLayout {
  featureFlagConnectionsRef: PreloadedQuery<MenuOptionsSidebar_Query>;
  bottomOptions: ISideMenuOption[];
  updatesClicked: boolean;
}
function MenuOptionsSidebarLayout({
  featureFlagConnectionsRef,
  bottomOptions,
  updatesClicked
}: IMenuOptionsSidebarLayout): JSX.Element {
  const {
    userId
  } = useUser();
  // We want access to URL parameters for overriding bandit subject key or assignment
  const router = useRouter();
  const [updatesHighlightStrategy, setUpdatesHighlightStrategy] = useState('');
  const {
    companyEnabledFeatures,
    userCompany,
    user,
    experimentationGettingStartedState,
    randomizationGettingStartedState
  } = usePreloadedQuery<MenuOptionsSidebar_Query>(QUERY, featureFlagConnectionsRef);
  const {
    canUpdateDefinition,
    canReadAdmin,
    canCreateDatabaseConnection,
    canCreateDefinition,
    canCreateMetric,
    canCreateExperiment,
    canCreateTeam,
    canCreateSdkKey,
    canCreateFeatureFlag,
    canUpdateFeatureFlag
  } = useRbacPermissions();
  const isTeamsEnabled = useStringFlagOn(EFeatureFlag.UserTeams);
  const isGettingStartedEnabled = useMemo(() => userCompany.isEppoOwned || (canCreateDatabaseConnection || canCreateDefinition || canCreateMetric || canCreateExperiment || canCreateTeam || canCreateSdkKey || canCreateFeatureFlag || canUpdateFeatureFlag) && !(isCompleted(experimentationGettingStartedState) && isCompleted(randomizationGettingStartedState)), [userCompany.isEppoOwned, canCreateDatabaseConnection, canCreateDefinition, canCreateMetric, canCreateExperiment, canCreateTeam, canCreateSdkKey, canCreateFeatureFlag, canUpdateFeatureFlag, experimentationGettingStartedState, randomizationGettingStartedState]);

  // Use our bandit proxy to request a way to highlight any new-to-the-user updates
  // This is done just once on initial load, and does not block rendering
  useEffect(() => {
    // We'll allow directly specifying the assignment with a URL parameter for testing
    if (router.query.banditAssignment) {
      setUpdatesHighlightStrategy(router.query.banditAssignment.toString());
      return;
    }
    const mostRecentUpdate = new Date('2024-09-11'); // Hard-coded for now, but in the future we can determine this dynamically
    const highlightUpdate = !user?.lastClickedUpdates || new Date(user?.lastClickedUpdates) < mostRecentUpdate;
    if (!user || !highlightUpdate) {
      return;
    }
    const highlightUpdateActions = {
      greenDot: {
        type: 'dot',
        color: 'green'
      },
      greenText: {
        type: 'text',
        color: 'green'
      },
      greenBackground: {
        type: 'background',
        color: 'green'
      },
      emojiSparkle: {
        type: 'emoji',
        color: 'yellow'
      }
    };
    const fetchData = async () => {
      const subjectKey = router.query.subjectKey ?? userId;
      // Some subject attributes
      const subjectAttributes = {
        userAgeMs: user.createdAt && Date.now() - new Date(user.createdAt).getTime(),
        companyName: userCompany.name,
        hourOfDay: new Date().getHours()
      };
      const response = await axios.post('https://us-central1-eppo-qa.cloudfunctions.net/java-http-function/assign', {
        subjectKey,
        flagKey: 'test-bandit-1',
        subjectAttributes,
        actions: highlightUpdateActions
      });
      const assignment = (response.data as {
        assignment: string | null;
      }).assignment;
      if (assignment) {
        setUpdatesHighlightStrategy(assignment);
      }
    };
    fetchData().catch(e => console.warn('Unable to fetch Updates highlight strategy', e));
  }, [user, userId, userCompany, router.query.subjectKey, router.query.banditAssignment]);
  const isInsightsEnabled = useBoolFlagAssignment(EFeatureFlag.KnowledgeBase);
  const isPipelineVisibilityEnabled = useBoolFlagAssignment(EFeatureFlag.PipelineVisibilityWarehouseLeftNav);
  const menuOptions = getMenuOptions({
    companyEnabledFeatures,
    isAdminEnabled: canReadAdmin,
    isTeamsEnabled,
    canUpdateDefinitions: canUpdateDefinition,
    isGettingStartedEnabled,
    isInsightsEnabled,
    isPipelineVisibilityEnabled
  });
  return <Sidebar options={menuOptions} bottomOptions={bottomOptions} updatesHighlightStrategy={updatesClicked ? undefined : updatesHighlightStrategy} />;
}